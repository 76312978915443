.root {
    display: flex;
    opacity: 1;
    padding:0px;
    overflow:hidden;
}
.subLi {
    font-size: 12px;
    opacity: 1;
    padding-top: 24px;
    color: #fff;
}

.mainLi {
    color: #fff;
    opacity: 1;
    font-size: 14px;
    margin-top:40px;
}
.leftGrid {
    background: transparent radial-gradient(closest-side at 44% 82%, var(---0090ff) 0%, var(---0059a9) 100%) 0% 0% no-repeat padding-box;
    background: transparent radial-gradient(closest-side at 44% 82%, #0090FF 0%, #0059A9 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.leftSubDiv {
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    background: transparent radial-gradient(closest-side at 44% 82%, #0090FF 0%, #0059A9 100%) 0% 0% no-repeat padding-box;
}

.leftMainDiv {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;
    background: transparent radial-gradient(closest-side at 44% 82%, var(---0090ff) 0%, var(---0059a9) 100%) 0% 0% no-repeat padding-box
}
